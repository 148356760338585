(function () {
  "use strict";
  angular.module("lariAidApp").constant("APP", {
    GLOBAL: {
      USER_GROUP: [
        "ROLE_USER",
        "ROLE_ADMIN",
        "LARI_ADMIN",
        "LARI_ANALYST",
        "LARI_MANAGER",
        "LARI_INVESTIGATOR",
        "LARI_READ_ONLY",
        "LARI_COUNTRY_ADMIN"
      ],
      ROLE_ADMIN: "LARI_ADMIN",
      ROLE_ANALYST: "LARI_ANALYST",
      ROLE_MANAGER: "LARI_MANAGER",
      ROLE_INVESTIGATOR: "LARI_INVESTIGATOR",
      ROLE_USER: "ROLE_USER",
      ROLE_VERNALIS_ADMIN: "ROLE_ADMIN",
      ROLE_READ_ONLY: "LARI_READ_ONLY",
      ROLE_COUNTRY_ADMIN : "LARI_COUNTRY_ADMIN",
      LDAP_GROUP: "LDAP_GROUP",
      EXTERNAL_INVESTIGATOR: "EXTERNAL_INVESTIGATOR",
      LDAP_INVESTIGATOR: "LDAP_INVESTIGATOR",
      ENDLESS: "endless",
      ADMIN: "admin",
      ANALYST: "analyst",
      MANAGER: "manager",
      INVESTIGATOR: "Registered Investigators",
      EXTERNALINVESTIGATOR: "Non-Registered Investigators",
      READ_ONLY: "readOnly",
      ASC: "asc",
      DESC: "desc",
      DEFAULT_SIZE: 15,
      ENGLISH: "ENGLISH",
      SPANISH: "SPANISH",
      EN: "en",
      ES: "es",
      PDF: ".pdf",
      TRUE: "TRUE",
      FALSE: "FALSE",
      LOCAL_REGISTRY: "Local Registry",
      NATIONAL_REGISTRY: "National Registry",
      IN_PROGRESS: "IN_PROGRESS",
      COMPLETED: "COMPLETED",
      APP_VERSION: "appversion",
      ANSWER_YES: "answer_yes",
      ANSWER_NO: "answer_no",
      SIBLINGS: "Siblings",
      PFM: "PFM",
      SIBLINGIMPORTED: "siblingImported",
      VERSION_ONE: "v1",
      VERSION_TWO: "v2",
      COUNTRY_ADMIN: "COUNTRY ADMIN"
    },
    INVESTIGATION: {
      MY_INVESTIGATIONS: "My Investigations",
      REGISTRATION_TYPE: "REGISTRATION_TYPE",
      INVESTIGATION_TYPE: "INVESTIGATION_TYPE",
      HEADER_TYPE_ALL: "ALL",
      HEADER_TYPE_THIRTY: "THIRTY",
      HEADER_TYPE_FIFTEEN: "FIFTEEN",
      HEADER_TYPE_ALL_VALUE: "0",
      HALF_MONTH: "15",
      ONE_MONTH: "30",
      THREE_MONTH: "90",
      SIX_MONTH: "180",
      LOCAL_REGISTRY: "LOCAL_REGISTRY",
      NATIONAL_REGISTRY: "NATIONAL_REGISTRY",
      MEDICAL_INSTITUTION: "MEDICAL_INSTITUTION",
      RELIGIOUS_INSTITUTION: "RELIGIOUS_INSTITUTION",
      SCHOOL: "SCHOOL",
      DNA_CENTER: "DNA_CENTER",
      INVESTIGATOR_FORM: "INVESTIGATOR_FORM",
      ANALYST_FORM: "ANALYST_FORM",
      INVESTIGATORFORM: "Investigator Form",
      ANALYSTFORM: "Analyst Form",
      INVESTIGATOR_REPORT: "Investigator Form",
      ANALYST_REPORT: "Analyst Form",
      INVESTIGATOR_REPORT_NAME: "Investigator_Form",
      ANALYST_REPORT_NAME: "Analyst_Form",
      REVIEW_COMMITTEE_REPORT_NAME: "Review_Committee_Form",
      SUMMARY_CONFIMED: "summary_confirmed",
      SUMMARY_FAILED: "summary_failed",
      SUMMARY_NA: "summary_NA",
      SUMMARY_UNDETERMINED: "summary_undetermined",
      REPORT_API: "api/report/",
      DOWNLOAD_API: "api/assets/downloadDocument/",
      REQUESTED_BY_DEFAULT_COUNT: 4,
      REQUESTED_BY_MOBILE_COUNT: 6,
      ANALYST: "ANALYST",
      INVESTIGATOR: "INVESTIGATOR",
      EXPORTFILENAME: "Investigation Details.xlsx",
      EXPORT_API: "api/investigations/excel/data?user=",
      EXPORT_API_MYINVESTIGATION: "&isMyInvestigation=",
      ENABLEREOPENFLAG: "TRUE",
      INVESTIGATIONDELETEDPUPID: "investigatorFormDeleted",
      RC_DETAILS_SAVE: "Review Committee details had been saved successfully",
      ALREADR_ASSIGNED: "Analyst Form Already Assigned.",
      STATE: {
        BIRTH_CERTIFICATE_STATE: "birthCertificate",
        BIRTH_MEDICAL_STATE: "birthMedicalRecord",
        FAMILY_INFORMATION_STATE: "familyInformation",
        SCHOOL_INFORMATION_STATE: "schoolInformation",
        RELEGIOUS_INSTUTE_STATE: "religiousInstitution",
        NEIGHBOURHOOD_STATE: "neighbourhoodCanvas",
        RESIDENCY_STATE: "residencyQuestions",
        SUMMARY_STATE: "summary",
        DNA_INFORMATION_STATE: "dnaInformation",
        OTHER_STATE: "other"
      },
      COUNTRIES: "Countries"
    },
    INVESTIGATION_STATUS: {
      PENDING_INVESTIGATOR: "PENDING_INVESTIGATOR",
      DELETED_IN_EBIS: "DELETED_IN_EBIS",
      WAIVED_STATUS: "WAIVED",
      NA: "NA",
      PENDINGINVESTIGATOR: "Pending Investigator",
      PENDING_ANALYST: "PENDING_ANALYST",
      PENDINGANALYST: "Pending Analyst",
      UNDER_INVESTIGATION: "UNDER_INVESTIGATION",
      UNDERINVESTIGATION: "Under Investigation",
      ANALYST_REVIEW: "ANALYST_REVIEW",
      ANALYSTREVIEW: "Analyst Review",
      MANAGER_REVIEW: "MANAGER_REVIEW",
      MANAGERREVIEW: "Manager Review",
      REOPEN: "Reopen",
      REVIEW_COMMITTEE_SELECTED: "REVIEW_COMMITTEE_SELECTED",
      REVIEW_COMMITTEE_READY: "REVIEW_COMMITTEE_READY",
      REVIEW_COMMITTEE_TABLED: "REVIEW_COMMITTEE_TABLED",
      REVIEWCOMMITTEE_SELECTED: "Review Committee - Selected",
      REVIEWCOMMITTEE_READY: "Review Committee - Ready",
      REVIEWCOMMITTEE_TABLED: "Review Committee - Tabled",
      CONFIRMED: "Confirmed",
      FAILED: "Failed",
      UNDETERMINED: "Undetermined",
      WAIVED: "Waived",
      CREATED: "Created",
      SUBMITTED: "Submitted",
      UPDATED: "Updated",
      DELETED: "Deleted",
      ADDED: "Added",
      ASSIGNED: "Assigned",
      REMOVED: "Removed",
      REASSIGN: "Reassign",
      REVISIED: "Revisied",
      SENDBACK_INVESTIGATOR: "Send Back To Investigator",
      SENDBACK_ANALYST: "Send Back To Analyst",
      DELETEDINEBIS: "Deleted in eBis",
      BYPASSED: "ByPassed"
    },
    USER: {
      INTERNAL: "internal",
      EXTERNAL: "external",
      ADMIN: "country-admin",
      USER_ROLE_FILTER: "USER_ROLE",
      INVESTIGATION_CASES: "INVESTIGATION_CASES",
      USER_ROLE_KEY: "userRoleKey",
      INVESTIGATION_STATUS_KEY: "investigationStatusKey",
      COMPLETED: "Completed",
      SUBMITTED: "Submitted",
      ASSIGNED: "Assigned",
      USER_CREATED: "Created",
      /*USER_UPDATED : 'Name Updated', - The Keyword is changed in backEnd*/
      USER_UPDATED: "Updated",
      USER_PHONE_UPDATED: "Phone Number Updated",
      USER_LANGUAGE_UPDATED: "Language Updated",
      USER_COUNTRY_UPDATED: "Country Updated",
      USER_DELETED: "Removed",
      SORT_BY_COUNT: "caseCount",
      SORT_BY_COUNT_VALUE: "Sort By Case Open",
      SORT_BY_NAME: "firstName",
      SORT_BY_NAME_VALUE: "Sort By Name",
      PHONETYPE: "primary",
      ENTITYTYPE: "USER",
      USERPROFILE: "userprofile",
      DELETEDUSERS: "Deleted Users",
      USERWITHNOACTIVECASES: "Users with No Active Cases",
      COUNTRY_VISIBILITY_ADDED: "COUNTRY_VISIBILITY_ADDED",
			COUNTRY_VISIBILITY_REMOVED: "COUNTRY_VISIBILITY_REMOVED"
    },
    STATES: {
      INVESTIGATIONS: "INVESTIGATIONS",
      USERS: "USERS"
    },
    REF_TERM: {
      REGISTRATION_TYPE: "REGISTRATION_TYPE",
      INVESTIGATION_TYPE: "INVESTIGATION_TYPE",
      COUNTRY: "country",
      CONFIRMED: "CONFIRMED",
      FORM_CONFIRMED: "FORM_CONFIRMED",
      FAILED: "FAILED",
      FORM_FAILED: "FORM_FAILED",
      UNDETERMINED: "UNDETERMINED",
      FORM_UNDETERMINED: "FORM_UNDETERMINED",
      WAIVED: "WAIVED",
      FORM_WAIVED: "FORM_WAIVED",
      NA: "NA",
      FORM_NA: "FORM_NA",
      RC_CONFIRMED: "RC_CONFIRMED",
      RC_FAILED: "RC_FAILED",
      RC_UNDETERMINED: "RC_UNDETERMINED",
      RC_NA: "RC_NA",
      RC_TABLED: "RC_TABLED",
      DELETED_IN_EBIS: "DELETED_IN_EBIS",
      ENTITY_PROFILE: "ENTITY_PROFILE",
      SECTION: "SECTION",
      BIRTH_CERTIFICATE: "BIRTH_CERTIFICATE",
      BIRTH_AND_MEDICAL_RECORDS: "BIRTH_AND_MEDICAL_RECORDS",
      NEIGHBORHOOD_CANVAS: "NEIGHBORHOOD_CANVAS",
      FAMILY_INFORMATION: "FAMILY_INFORMATION",
      SCHOOL_INFORMATION: "SCHOOL_INFORMATION",
      RELIGIOUS_INSTITUTIONS: "RELIGIOUS_INSTITUTIONS",
      RESIDENCY_QUESTIONS: "RESIDENCY_QUESTIONS",
      SUMMARY: "SUMMARY",
      DNA: "DNA",
      OTHER: "OTHER",
      IN_PROGRESS: "In progress",
      ZERO_INDEX: "0",
      FIVE_HUNDRED: 500,
      FIVE_HUNDRED_AND_ONE: 501,
      FORM_SAVE: "FORM_SAVE",
      FORM_SUBMIT: "FORM_SUBMIT",
      FORM_SECTION_SAVE: "FORM_SECTION_SAVE",
      FORM_COPY: "FORM_COPY",
      FORM_ATTACHMENT_ADD: "FORM_ATTACHMENT_ADD",
      FORM_ATTACHMENT_DELETE: "FORM_ATTACHMENT_DELETE"
    },
    LEGACYLARI: {
      INTERNAL_SERVER_ERROR: "Internal Server Error",
      NO_DOCUMENT_FOUND: "The document not found",
      ERROR_DOWNLOADING_DOCUMENT: "Error in downloading the document",
      DOCUMENT_DOWNLOADED: "The document will get downloaded",
      NO_EBIS_ID_FOUND: "The Ebis Id is not Present",
      UNABLE_TO_CONNECT_LEGACY_LARI_SYSTEM: "Error connecting to the Legacy LARI System",
      BAD_REQUEST: "Exception occur while download document"
    },
    LIBRARY: {
      REQUEST: "request",
      ENTITY: "entities",
      LOCATION: "locations",
      OPEN_REQUEST: "OPEN_REQUEST",
      CLOSED_REQUEST: "CLOSED_REQUEST",
      ENTITY_SORT_BY_COUNT: "approvedOn",
      ENTITY_SORT_BY_COUNT_VALUE: "Sort By Approved Days",
      ENTITY_SORT_BY_NAME: "name",
      ENTITY_SORT_BY_NAME_VALUE: "Sort By Name",
      ENTITY_TYPE_ID: "entityTypeId",
      LOCATION_MAPPING_ID: "locationMappingId",
      REQUEST_STATUS_KEY: "statusKey",
      REQUEST_SORT_BY_COUNT: "daysCount",
      REQUEST_SORT_BY_COUNT_VALUE: "Sort By Days Open",
      REQUEST_SORT_BY_NAME: "firstName, lastName",
      REQUEST_SORT_BY_NAME_VALUE: "Sort By Name",
      ENTITY_REQ: "ENTITY REQUEST",
      LOCATION_REQ: "LOCATION REQUEST",
      CLOSED_REQUESTS: ["Approved", "Altered"],
      COUNTRY: "Country",
      PROVINCE: "Province",
      CITY: "City",
      SECTOR: "Sector",
      COUNTRY_CAPS: "COUNTRY",
      PROVINCE_CAPS: "PROVINCE",
      CITY_CAPS: "CITY",
      SECTOR_CAPS: "SECTOR",
      LOCATION_CONST: "Location",
      RECENTY_ADD: "Recently Added",
      REQ_LOC_ID: "requestLocation",
      REQ_LOC_PROFILE_ID: "locationProfilePopupId",
      REQ_ENTITY_ID: "requestEntity",
      REQ_ENTITY_PROFILE_ID: "entityProfilePopupId",
      REQ_ENTITY_ICON_ID: "entityIconPopupId",
      USER_SAVE: "Investigator had been added successfully",
      USER_SAVE_PROBLEM: "Problem occured in adding investigator",
      USER_EDIT: "Investigator had been updated successfully",
      USER_EDIT_PROBLEM: "Problem occured in adding investigator",
      ENTITY_REQUEST: "Entity Request is Approved/Altered",
      ENTITY_REQUEST_PROBLEM: "Problem occured while approving request",
      LOCATION_REQUEST: "Location is Approved/Altered successfully",
      LOCATION_REQUEST_PROBLEM: "Problem occured while approving location",
      LOCATION_DELETED: "Location had been deleted successfully",
      LOCATION_DELETED_PROBLEM: "Problem occured in deleting location",
      LOCATION_EDITED: "Location had been updated successfully",
      LOCATION_EDITED_PROBLEM: "Problem occured in updating location",
      LOCATION_ADD: "Location had been added successfully",
      LOCATION_ADD_PROBLEM: "Problem occured in adding location",
      ENTITY_DELETE: "Entity had been deleted successfully",
      ENTITY_DELETE_PROBLEM: "Problem occured in deleting entity",
      ENTITY_ADD: "Entity had been added successfully",
      ENTITY_ADD_PROBLEM: "Problem occured in adding entity",
      ENTITY_UPDATE: "Entity had been updated successfully",
      ENTITY_UPDATE_PROBLEM: "Problem occured in updating entity",
      DELETE_PROBLEM: "Cannot delete locations if they are mapped to other locations",
      ALL: "All",
      FORM_LOCATION_REQUEST: "FORM LOCATION REQUEST",
      LOCATION_REQUEST_SEND: "Location request sent successfully",
      LOCATION_REQUEST_SEND_PROBLEM: "Problem occured in sending entity request",
      ENTITY_REQUEST_SEND: "Entity request sent successfully",
      LOCATION_EXISTS: "The Requested New Location is already Applied!!",
      ENTITY_REQUEST_SEND_PROBLEM: "Problem occured in sending entity request",
      ENTITY_REQUEST_EXISTS: "The Requested New Entity has been already Applied!!",
      COUNTRY_ADMIN_SAVE: "The Country Admin Details has been Saved",
			COUNTRY_ADMIN_SAVE_PROBLEM: "Problem occured in saving country admin details",
			COUNTRY_ADMIN_GET_PROBLEM: "Problem while getting the Admin Data"
    },
    INVESTIGATION_FORM: {
      BIRTH_AND_MEDICAL_RECORDS: {
        QUESTION1: "birthMedical1",
        QUESTION2_MIDWIFE: "birthMedical2_mid_wife",
        QUESTION2A_MIDWIFE: "birthMedical2_mid_wife_no_2a",
        QUESTION2_MEDICAL: "birthMedical2_medical_institution",
        QUESTION2_MEDICAL_YES_2A: "birthMedical2_medical_institution_yes_2a",
        QUESTION2_MEDICAL_YES_2B: "birthMedical2_medical_institution_yes_2b",
        QUESTION3_MEDICAL: "birthMedical3_medical_institution",
        QUESTION3_MEDICAL_YES_3A: "birthMedical3_medical_institution_yes_3a",
        QUESTION3_MEDICAL_NO_3A: "birthMedical3_medical_institution_no_3a",
        QUESTION4_MEDICAL: "birthMedical4_medical_institution",
        QUESTION5_MEDICAL: "birthMedical5_medical_institution",
        QUESTION6_MEDICAL: "birthMedical6_medical_institution",
        QUESTION3_MEDICAL_YES_3B: "birthMedical3_medical_institution_yes_3b"
      },
      NEIGHBORHOOD_CANVAS: {
        QUESTION1: "neighborhood_1"
      },
      FAMILY_QUESTIONS: {
        QUESTION1_FATHER: "familyInformation_father_1",
        QUESTION1_MOTHER: "familyInformation_mother_1",
        QUESTION1_PROSPECT: "familyInformation_prospect_1",
        QUESTION1_FAMILY_MEMBER: "familyInformation_familyMember_1",
        QUESTION1_SIBLING: "familyInformation_sibling_1",
        QUESTION1_PROSPECT_1A: "familyInformation_prospect_1a",
        QUESTION1_PROSPECT_1B: "familyInformation_prospect_1b",
        QUESTION1_PROSPECT_1C: "familyInformation_prospect_1c",
        QUESTION1_PHOTO: "familyInformation_familyPhoto_1",
        QUESTION2_PHOTO: "familyInformation_familyPhoto_2",
        PHOTO_INDEX: "101"
      },
      SCHOOL_QUESTIONS: {
        QUESTION1: "schoolInformation_1",
        QUESTION2: "schoolInformation_2",
        QUESTION2_YES_2A: "schoolInformation_2_yes_2a",
        QUESTION2_YES_2B: "schoolInformation_2_yes_2b_label",
        QUESTION3: "schoolInformation_3",
        QUESTION3_YES_4: "schoolInformation_3_yes_3a",
        QUESTION3_YES_5: "schoolInformation_3_yes_3b",
        QUESTION3_YES_6: "schoolInformation_3_yes_3c",
        QUESTION3_YES_7: "schoolInformation_3_yes_3d",
        QUESTION3_NO_3A: "schoolInformation_3_no_1"
      },
      RELIGIOUS_QUESTIONS: {
        QUESTION1: "religiousInstitution_q1",
        QUESTION1_NO_1A: "religiousInstitution_q1_no_notBaptised_records_notAvailable",
        QUESTION2: "religiousInstitution_q2",
        QUESTION2A: "religiousInstitution_q2a_dateOfVisit",
        QUESTION2B: "religiousInstitution_q2a",
        QUESTION3: "religiousInstitution_q3",
        QUESTION3A: "religiousInstitution_q3_recordType",
        QUESTION4: "religiousInstitution_q4",
        QUESTION5: "religiousInstitution_q5",
        QUESTION6: "religiousInstitution_q6",
        QUESTION3B: "religiousInstitution_q3b"
      },
      DNA_QUESTIONS: {
        QUESTION1: "dnaInformation_1",
        QUESTION1A: "dnaInformation_1a"
      },
      RESIDENCY_QUESTIONS: {
        QUESTION1: "residency1",
        QUESTION2: "residency2"
      },
      SUMMARY_QUESTIONS: {
        QUESTION1: "summary_age",
        QUESTION2: "summary_identity",
        QUESTION3: "summary_3",
        QUESTION4: "summary_4",
        QUESTION5: "summary_5",
        QUESTION6: "summary_6"
      },
      SUMMARY_KEY: "summary",
      UPLOAD_DOCUMENT_CONFIRMATION_POPUPID: "uploadFileConfirmationPopup",
      PREVIEW_IMAGE: "previewImage",
      VIEW_DOCUMENT_BUTTON: "viewDocumentBtn",
      VIEW_BUTTON_CLICK: "viewButtonClick",
      BIRTH_CERTIFICATE_FORM: "bithcerticateForm",
      BIRTH_AND_MEDICAL_FORM: "birthAndMedicalForm",
      NEIGHBOURHOOD_CANVASS_FORM: "neighbourhoodCanvasForm",
      FAMILY_FORM: "familyInfoForm",
      SCHOOL_FORM: "schoolInfoForm",
      RELIGIOUS_FORM: "religiousInstitutionForm",
      RESIDENCY_FORM: "residencyQuestionForm",
      DNA_FORM: "dnaInfoForm",
      OTHER_FORM: "otherForm",
      SUMMARY_FORM: "summaryForm",
      SUMMARY_CLOSE_FORM: "saveclosePopup"
    }
  });
})();
