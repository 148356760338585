(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('lariAidApp')
        .constant('VERSION', "1.9.27")
        .constant('BASE_URL', "https://api.lariaidstg.mlb.com/")
        .constant('OKTA_SAML_URL', "https://mlbtest.okta.com/home/mlbtest_lariaidstgsaml_1/0oaqio7estSinZcVd1t7/alnqioc8lkaFwYcvy1t7")
        .constant('DEBUG_INFO_ENABLED', false)
;
})();
